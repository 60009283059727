import React, { Suspense, lazy } from "react";
import HeaderDark from "../../../../common/header/HeaderDark";
// Lazy load the page components

const Banner = lazy(() => import("./banner"));

const Hero = () => {
  return (
    <>
      <HeaderDark />

      <Suspense fallback={<div className="loading"></div>}>
        <Banner />
      </Suspense>
      {/* Inline critical CSS */}
      <style jsx>
        {`
          .cus-pd-24 {
            padding-left: 2.55rem;
            padding-right: 2.55rem;
          }
          .section-padding {
            padding-top: 100px;
            padding-bottom: 100px;
          }
          .banner-sec {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 5rem 0 2rem;
            overflow: hidden;
          }
          .bg-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
          }
          .custom-cn-width {
            max-width: 1140px;
            margin: 0 auto;
          }
          .banner-video {
            object-fit: cover;
            object-position: center;
            border-radius: 20px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          }
          .bg-overlay {
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
          }
          .hero-heading {
            color: #1a1a1a;
            font-weight: 700;
            font-size: 48px;
            margin-bottom: 3rem;
          }
          span.lazy-load-image-background.blur.lazy-load-image-loaded {
            width: 100%;
          }
          @media (min-width: 1400px) {
            .custom-cn-width {
              max-width: 1320px;
            }
          }
          @media (max-width: 575px) {
            .section-padding {
              padding-top: 50px;
              padding-bottom: 50px;
            }
            .hero-heading {
              font-size: 42px;
              margin-bottom: 1rem;
            }
          }
        `}
      </style>
    </>
  );
};

export default Hero;
